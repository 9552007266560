// src/components/CreateNewCharity.js

import React, { useState, useEffect, useCallback } from 'react';
import { FaUpload, FaFileAlt, FaTrash, FaSpinner, FaHandHoldingHeart, FaBuilding, FaListAlt, FaPlus, FaMinus } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Papa from 'papaparse';

import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Loader from '../components/Loader';
import { authService } from '../services/authService';
import { charityService } from '../services/charityService';
import { mapService } from '../services/mapService';
import { userService } from '../services/userService';

import '../styles/CreateNewCharity.css';

function CreateNewCharity() {
  const [user, setUser] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [fileName, setFileName] = useState('');
  const [allCharities, setAllCharities] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [charityMode, setCharityMode] = useState('automatic');

  const fetchAllCharities = useCallback(async () => {
    try {
      const charities = await charityService.getAll();
      setAllCharities(charities);
    } catch (error) {
      console.error('Error fetching charities:', error);
      toast.error('Failed to fetch charities. Please refresh the page.');
    }
  }, []);

  useEffect(() => {
    const fetchUserAndCharities = async () => {
      setIsLoading(true);
      try {
        const currentUser = await authService.getCurrentUser();
        const userData = authService.getUserSession();
  
        if (currentUser) {
          if (userData && userData.role === 'SystemAdmin') {
            const fullUserData = await userService.getById(userData.id);
            setUser(fullUserData);
            await fetchAllCharities();
          } else {
            toast.error('You do not have permission to access this page.');
          }
        } else {
          toast.error('Please log in to access this page.');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        toast.error('An error occurred while loading the page.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserAndCharities();
  }, [fetchAllCharities]);

  const validateCsvData = (data) => {
    const requiredFields = ['name', 'logourl', 'street', 'city', 'state', 'zipcode', 'country', 'phone', 'email', 'website', 'accepteditems', 'description', 'taxid', 'donationinstructions'];
    const errors = [];
  
    data.forEach((row, index) => {
      requiredFields.forEach(field => {
        if (!row[field]) {
          errors.push(`Row ${index + 2}: Missing ${field}`);
        }
      });
  
      if (row.accepteditems && !row.accepteditems.split(' ').every(item => item.trim() !== '')) {
        errors.push(`Row ${index + 2}: Invalid accepted items`);
      }
    });
  
    return errors;
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setFileName(file.name);
    setError('');
    setCsvData([]);
  
    Papa.parse(file, {
      complete: (result) => {
        if (result.data && result.data.length > 1) {
          const headers = result.data[0].map(header => header.trim().toLowerCase());
          const parsedData = result.data.slice(1)
            .filter(row => row.length === headers.length && row.some(cell => cell !== ''))
            .map(row => {
              const item = {};
              headers.forEach((header, index) => {
                item[header] = row[index].trim();
              });
              return item;
            });
  
          const validationErrors = validateCsvData(parsedData);
          if (validationErrors.length > 0) {
            setError(validationErrors.join('\n'));
          } else {
            setCsvData(parsedData);
          }
        } else {
          setError('The CSV file is empty or invalid');
        }
      },
      header: false,
      error: (error) => {
        console.error("CSV parsing error:", error);
        setError(`Error parsing CSV: ${error.message}`);
      }
    });
  };

  const handleSubmit = async (e, manualCharity = null) => {
    e.preventDefault();
    if (charityMode === 'automatic' && csvData.length === 0) {
      toast.error('Please upload a valid CSV file');
      return;
    }
  
    setIsLoading(true);
    setError('');
    setSuccessMessage('');
  
    const createdCharities = [];
    const failedCharities = [];
  
    const charitiesToProcess = charityMode === 'automatic' ? csvData : [manualCharity];
  
    for (const charity of charitiesToProcess) {
      try {
        const address = `${charity.street}, ${charity.city}, ${charity.state} ${charity.zipcode}, ${charity.country}`;
        let geocodedLocation;
        try {
          geocodedLocation = await mapService.geocodeAddress(address);
        } catch (geocodeError) {
          console.error(`Failed to geocode address for ${charity.name}:`, geocodeError);
          geocodedLocation = null;
        }

        const newCharityData = {
          name: charity.name,
          logoUrl: charity.logourl,
          address: {
            street: charity.street,
            city: charity.city,
            state: charity.state,
            zipCode: charity.zipcode,
            country: charity.country,
            latitude: geocodedLocation ? geocodedLocation.latitude : null,
            longitude: geocodedLocation ? geocodedLocation.longitude : null,
          },
          contactInfo: {
            phone: charity.phone,
            email: charity.email,
            website: charity.website
          },
          acceptedItems: charity.accepteditems.split(' '),
          description: charity.description,
          taxId: charity.taxid,
          operatingHours: {
            monday: charity.monday,
            tuesday: charity.tuesday,
            wednesday: charity.wednesday,
            thursday: charity.thursday,
            friday: charity.friday,
            saturday: charity.saturday,
            sunday: charity.sunday
          },
          donationInstructions: charity.donationinstructions
        };
  
        const newCharityId = await charityService.create(newCharityData);
        createdCharities.push(newCharityId);
        console.log(`Charity created: ${charity.name}`);
      } catch (error) {
        console.error(`Failed to create charity: ${charity.name}`, error);
        failedCharities.push(charity.name);
      }
    }
  
    if (createdCharities.length > 0) {
      setSuccessMessage(`${createdCharities.length} charities created successfully!`);
      await fetchAllCharities();
    }
  
    if (failedCharities.length > 0) {
      setError(`Failed to create the following charities: ${failedCharities.join(', ')}`);
    }
  
    setCsvData([]);
    setFileName('');
    setIsLoading(false);
  };

  const handleRemoveFile = () => {
    setCsvData([]);
    setFileName('');
    setError('');
  };

  if (isLoading) return <Loader />;
  if (!user) return <div>Please log in to access this page.</div>;

  return (
    <div className="app-container">
      <Header user={user} />
      <div className="main-content">
        <Sidebar />
        <div className="create-new-charity-container">
          <h1><FaHandHoldingHeart /> Create New Charity</h1>
          <CharityForm 
            error={error}
            successMessage={successMessage}
            handleSubmit={handleSubmit}
            handleFileUpload={handleFileUpload}
            fileName={fileName}
            handleRemoveFile={handleRemoveFile}
            csvData={csvData}
            isLoading={isLoading}
            charityMode={charityMode}
            setCharityMode={setCharityMode}
          />
          {csvData.length > 0 && charityMode === 'automatic' && <CsvPreview csvData={csvData} />}
          <CharitiesList charities={allCharities} />
        </div>
      </div>
      <ToastContainer position="bottom-right" autoClose={5000} />
    </div>
  );
}

function CharityForm({ error, successMessage, handleSubmit, handleFileUpload, fileName, handleRemoveFile, csvData, isLoading, charityMode, setCharityMode }) {
  return (
    <div className="create-new-charity-form">
      <h2>New Charity Details</h2>
      {error && <p className="error-message">{error}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}
      <div className="form-group">
        <label htmlFor="charity-mode"><FaListAlt /> Charity Mode</label>
        <select
          id="charity-mode"
          value={charityMode}
          onChange={(e) => setCharityMode(e.target.value)}
        >
          <option value="automatic">Automatic (CSV Upload)</option>
          <option value="manual">Manual Entry</option>
        </select>
      </div>
      {charityMode === 'automatic' ? (
        <AutomaticCharityForm
          handleFileUpload={handleFileUpload}
          fileName={fileName}
          handleRemoveFile={handleRemoveFile}
        />
      ) : (
        <ManualCharityForm handleSubmit={handleSubmit} />
      )}
      <button 
        type="submit" 
        onClick={(e) => handleSubmit(e, charityMode === 'manual' ? ManualCharityForm.getCharity() : null)}
        disabled={isLoading || (charityMode === 'automatic' && csvData.length === 0)}
        className="submit-button"
      >
        {isLoading ? (
          <>
            <FaSpinner className="spinner" /> Creating...
          </>
        ) : (
          'Create Charities'
        )}
      </button>
    </div>
  );
}

function AutomaticCharityForm({ handleFileUpload, fileName, handleRemoveFile }) {
  return (
    <div className="form-group file-upload">
      <label htmlFor="csv-upload">
        <FaUpload /> Upload Charities CSV
      </label>
      <input
        type="file"
        id="csv-upload"
        accept=".csv"
        onChange={handleFileUpload}
        required
      />
      {fileName && (
        <div className="file-info">
          <FaFileAlt /> {fileName}
          <button type="button" onClick={handleRemoveFile} className="remove-file">
            <FaTrash />
          </button>
        </div>
      )}
    </div>
  );
}

function ManualCharityForm({ handleSubmit }) {
  const [charity, setCharity] = useState({
    name: '',
    logourl: '',
    street: '',
    city: '',
    state: '',
    zipcode: '',
    country: '',
    phone: '',
    email: '',
    website: '',
    accepteditems: '',
    description: '',
    taxid: '',
    donationinstructions: '',
    monday: '',
    tuesday: '',
    wednesday: '',
    thursday: '',
    friday: '',
    saturday: '',
    sunday: ''
  });

  const handleChange = (field, value) => {
    setCharity({ ...charity, [field]: value });
  };

  ManualCharityForm.getCharity = () => charity;

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="name">Name</label>
        <input
          id="name"
          type="text"
          value={charity.name}
          onChange={(e) => handleChange('name', e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="logourl">Logo URL</label>
        <input
          id="logourl"
          type="text"
          value={charity.logourl}
          onChange={(e) => handleChange('logourl', e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="street">Street</label>
        <input
          id="street"
          type="text"
          value={charity.street}
          onChange={(e) => handleChange('street', e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="city">City</label>
        <input
          id="city"
          type="text"
          value={charity.city}
          onChange={(e) => handleChange('city', e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="state">State</label>
        <input
          id="state"
          type="text"
          value={charity.state}
          onChange={(e) => handleChange('state', e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="zipcode">Zip Code</label>
        <input
          id="zipcode"
          type="text"
          value={charity.zipcode}
          onChange={(e) => handleChange('zipcode', e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="country">Country</label>
        <input
          id="country"
          type="text"
          value={charity.country}
          onChange={(e) => handleChange('country', e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="phone">Phone</label>
        <input
          id="phone"
          type="tel"
          value={charity.phone}
          onChange={(e) => handleChange('phone', e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="email"
          value={charity.email}
          onChange={(e) => handleChange('email', e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="website">Website</label>
        <input
          id="website"
          type="url"
          value={charity.website}
          onChange={(e) => handleChange('website', e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="accepteditems">Accepted Items (space-separated)</label>
        <input
          id="accepteditems"
          type="text"
          value={charity.accepteditems}
          onChange={(e) => handleChange('accepteditems', e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="description">Description</label>
        <textarea
          id="description"
          value={charity.description}
          onChange={(e) => handleChange('description', e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="taxid">Tax ID</label>
        <input
          id="taxid"
          type="text"
          value={charity.taxid}
          onChange={(e) => handleChange('taxid', e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="donationinstructions">Donation Instructions</label>
        <textarea
          id="donationinstructions"
          value={charity.donationinstructions}
          onChange={(e) => handleChange('donationinstructions', e.target.value)}
          required
        />
      </div>
      <h3>Operating Hours</h3>
      {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((day) => (
        <div key={day} className="form-group">
          <label htmlFor={day}>{day.charAt(0).toUpperCase() + day.slice(1)}</label>
          <input
            id={day}
            type="text"
            value={charity[day]}
            onChange={(e) => handleChange(day, e.target.value)}
            placeholder="e.g., 9:00 AM - 5:00 PM"
          />
        </div>
      ))}
    </form>
  );
}

function CsvPreview({ csvData }) {
  if (csvData.length === 0) return null;

  return (
    <div className="csv-preview">
      <h3><FaListAlt /> CSV Preview</h3>
      <div className="csv-table-container">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Logo URL</th>
              <th>Address</th>
              <th>Contact Info</th>
              <th>Accepted Items</th>
              <th>Description</th>
              <th>Tax ID</th>
              <th>Donation Instructions</th>
            </tr>
          </thead>
          <tbody>
            {csvData.map((row, index) => (
              <tr key={index}>
                <td>{row.name}</td>
                <td>
                  <img 
                    src={row.logourl} 
                    alt={`${row.name} logo`} 
                    style={{width: '50px', height: '50px'}}
                  />
                </td>
                <td>{`${row.street}, ${row.city}, ${row.state} ${row.zipcode}, ${row.country}`}</td>
                <td>{`${row.phone}, ${row.email}, ${row.website}`}</td>
                <td>{row.accepteditems}</td>
                <td>{row.description}</td>
                <td>{row.taxid}</td>
                <td>{row.donationinstructions}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <p className="preview-note">Showing all {csvData.length} charities</p>
    </div>
  );
}

function CharitiesList({ charities }) {
  return (
    <div className="all-charities">
      <h3>All Charities</h3>
      {charities.length > 0 ? (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Contact Info</th>
                <th>Accepted Items</th>
                <th>Tax ID</th>
              </tr>
            </thead>
            <tbody>
              {charities.map((charity) => (
                <tr key={charity.id}>
                  <td>{charity.name}</td>
                  <td>{`${charity.contactInfo.phone}, ${charity.contactInfo.email}`}</td>
                  <td>{charity.acceptedItems.join(', ')}</td>
                  <td>{charity.taxId}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No charities found</p>
      )}
    </div>
  );
}

export default CreateNewCharity;