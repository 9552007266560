// src/components/AssignDonation.js

import React, { useState, useEffect } from 'react';
import { FaSearch, FaUpload, FaDollarSign, FaSave, FaMapMarkerAlt, FaBox, FaGlobe, FaCheck, FaPhone, FaEnvelope, FaPlus, FaMinus } from 'react-icons/fa';
import { charityService } from '../services/charityService';
import { donationService } from '../services/donationService';
import { storage } from '../firebase/config';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import '../styles/AssignDonation.css';

const AssignDonation = ({ item, onAssign, onClose }) => {
  const [palletGroups, setPalletGroups] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [charities, setCharities] = useState([]);
  const [selectedCharity, setSelectedCharity] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [focusedInputs, setFocusedInputs] = useState({});

  useEffect(() => {
    const fetchInitialData = async () => {
      const fetchedCharities = await charityService.getAll();
      setCharities(fetchedCharities);

      if (item.assignedCharities && item.assignedCharities.length > 0) {
        const groups = item.assignedCharities.map((charity, index) => {
          const matchedCharity = fetchedCharities.find(c => c.id === charity.charityId) || {};
          return {
            palletGroup: index + 1,
            palletQuantity: charity.palletCount,
            charityId: charity.charityId,
            charityName: matchedCharity.name || charity.charityName,
            quoteFile: null,
            quoteUrl: item.shippingQuotes ? item.shippingQuotes[index]?.url : '',
            quotePrice: item.shippingQuotes ? item.shippingQuotes[index]?.price.toString() : ''
          };
        });
        setPalletGroups(groups);
      } else {
        const initialGroup = {
          palletGroup: 1,
          palletQuantity: item.palletCount,
          charityId: '',
          charityName: '',
          quoteFile: null,
          quoteUrl: '',
          quotePrice: ''
        };
        setPalletGroups([initialGroup]);
      }
    };
    fetchInitialData();
  }, [item]);

  useEffect(() => {
    const searchCharities = async () => {
      if (searchTerm) {
        const searchResults = await charityService.searchByName(searchTerm);
        setCharities(searchResults);
      } else {
        const allCharities = await charityService.getAll();
        setCharities(allCharities);
      }
    };
    searchCharities();
  }, [searchTerm]);

  const formatAddress = (address) => {
    if (!address) return 'Address not available';
    return `${address.city}, ${address.state} ${address.zipCode}`;
  };

  const handleCharitySelection = (charity) => {
    setSelectedCharity(charity);
  };

  const handleCharityAssignment = (groupIndex) => {
    if (selectedCharity) {
      const newGroups = [...palletGroups];
      newGroups[groupIndex] = {
        ...newGroups[groupIndex],
        charityId: selectedCharity.id,
        charityName: selectedCharity.name
      };
      setPalletGroups(newGroups);
    }
  };

  const handleQuoteUpload = (groupIndex, file) => {
    const newGroups = [...palletGroups];
    newGroups[groupIndex].quoteFile = file;
    setPalletGroups(newGroups);
  };

  const formatCurrency = (value) => {
    if (!value) return '$0.00';
    return `$${parseFloat(value).toFixed(2)}`;
  };

  const handleQuotePriceChange = (groupIndex, value) => {
    const numericValue = value.replace(/[^0-9.]/g, '');
    const newGroups = [...palletGroups];
    newGroups[groupIndex].quotePrice = numericValue;
    setPalletGroups(newGroups);
  };

  const handleQuotePriceFocus = (groupIndex) => {
    setFocusedInputs(prev => ({ ...prev, [groupIndex]: true }));
  };

  const handleQuotePriceBlur = (groupIndex) => {
    setFocusedInputs(prev => ({ ...prev, [groupIndex]: false }));
    const newGroups = [...palletGroups];
    if (newGroups[groupIndex].quotePrice) {
      newGroups[groupIndex].quotePrice = parseFloat(newGroups[groupIndex].quotePrice).toFixed(2);
    }
    setPalletGroups(newGroups);
  };

  const handleAddGroup = () => {
    const lastGroup = palletGroups[palletGroups.length - 1];
    if (lastGroup.palletQuantity > 1) {
      const newGroup = {
        palletGroup: palletGroups.length + 1,
        palletQuantity: 1,
        charityId: '',
        charityName: '',
        quoteFile: null,
        quoteUrl: '',
        quotePrice: ''
      };
      const updatedGroups = palletGroups.map((group, index) => 
        index === palletGroups.length - 1 
          ? { ...group, palletQuantity: group.palletQuantity - 1 }
          : group
      );
      setPalletGroups([...updatedGroups, newGroup]);
    }
  };

  const handleRemoveGroup = (groupIndex) => {
    if (palletGroups.length > 1) {
      const removedQuantity = palletGroups[groupIndex].palletQuantity;
      const newGroups = palletGroups.filter((_, index) => index !== groupIndex);
      newGroups[newGroups.length - 1].palletQuantity += removedQuantity;
      setPalletGroups(newGroups);
    }
  };

  const handleQuantityChange = (groupIndex, change) => {
    const newGroups = [...palletGroups];
    const currentGroup = newGroups[groupIndex];
    const nextGroup = newGroups[groupIndex + 1];

    if (change === 1 && nextGroup && nextGroup.palletQuantity > 1) {
      currentGroup.palletQuantity += 1;
      nextGroup.palletQuantity -= 1;
    } else if (change === -1 && currentGroup.palletQuantity > 1) {
      currentGroup.palletQuantity -= 1;
      if (nextGroup) {
        nextGroup.palletQuantity += 1;
      } else {
        newGroups.push({
          palletGroup: newGroups.length + 1,
          palletQuantity: 1,
          charityId: '',
          charityName: '',
          quoteFile: null,
          quoteUrl: '',
          quotePrice: ''
        });
      }
    }

    setPalletGroups(newGroups);
  };

  const uploadQuotesToFirebase = async () => {
    const quoteUploads = palletGroups.map(async (group, index) => {
      if (group.quoteFile) {
        const storageRef = ref(storage, `shippingQuotes/${item.donationId}/${item.itemID}/${index + 1}_${group.quoteFile.name}`);
        await uploadBytes(storageRef, group.quoteFile);
        const downloadUrl = await getDownloadURL(storageRef);
        console.log(`Quote uploaded for group ${index + 1}:`, downloadUrl);
        return { ...group, quoteUrl: downloadUrl };
      }
      return group;
    });

    return Promise.all(quoteUploads);
  };

  const handleSave = async () => {
    setErrorMessage('');

    const isIncomplete = palletGroups.some(group => 
      !group.charityId || (!group.quoteFile && !group.quoteUrl) || !group.quotePrice
    );

    if (isIncomplete) {
      setErrorMessage('Please complete all fields for each pallet group before saving.');
      return;
    }

    setIsUploading(true);
    try {
      const updatedGroups = await uploadQuotesToFirebase();
     
      const totalQuotePrice = updatedGroups.reduce((sum, group) => sum + (parseFloat(group.quotePrice || 0) * group.palletQuantity), 0);
     
      const shippingQuotes = updatedGroups
        .filter(group => group.quoteUrl)
        .map(group => ({
          palletGroup: group.palletGroup,
          palletQuantity: group.palletQuantity,
          url: group.quoteUrl,
          price: parseFloat(group.quotePrice || 0)
        }));

      const assignedCharities = updatedGroups.reduce((acc, group) => {
        if (group.charityId) {
          const existingCharity = acc.find(c => c.charityId === group.charityId);
          if (existingCharity) {
            existingCharity.palletCount += group.palletQuantity;
          } else {
            acc.push({ 
              charityId: group.charityId, 
              charityName: group.charityName,
              palletCount: group.palletQuantity 
            });
          }
        }
        return acc;
      }, []);

      const updatedItem = {
        ...item,
        assignedCharities: assignedCharities,
        shippingQuotes: shippingQuotes,
        shippingQuotePrice: totalQuotePrice,
        status: 'Quoted'
      };

      onAssign(updatedItem);
      setIsUploading(false);
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
        onClose();
      }, 3000);
    } catch (error) {
      console.error("Error saving assignments:", error);
      setIsUploading(false);
      setErrorMessage(error.message || 'An error occurred while saving');
    }
  };

  return (
    <div className="assign-donation-container">
      <h3>Assign Donation: {item.description}</h3>
      <div className="charity-search">
        <FaSearch className="search-icon" />
        <input
          type="text"
          placeholder="Search charities..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="charity-browser">
        {charities.map(charity => (
          <div
            key={charity.id}
            className={`charity-card ${selectedCharity?.id === charity.id ? 'selected' : ''}`}
            onClick={() => handleCharitySelection(charity)}
          >
            <div className="charity-header">
              <div className="charity-logo-container">
                <img src={charity.logoUrl} alt={`${charity.name} logo`} className="charity-logo" />
              </div>
              <h4>{charity.name}</h4>
            </div>
            <div className="charity-info">
              <p><FaMapMarkerAlt className="icon" /> {formatAddress(charity.address)}</p>
              <p><FaBox className="icon" /> {charity.acceptedItems.join(', ')}</p>
              <p><FaPhone className="icon" /> {charity.contactInfo.phone}</p>
              <p><FaEnvelope className="icon" /> {charity.contactInfo.email}</p>
              <a href={`https://${charity.contactInfo.website}`} target="_blank" rel="noopener noreferrer" className="website-link">
                <FaGlobe className="icon" /> Visit Website
              </a>
            </div>
            {selectedCharity?.id === charity.id && (
              <div className="selected-indicator">
                <FaCheck className="icon" />
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="pallet-group-assignments">
        <h4>Pallet Group Assignments</h4>
        {palletGroups.map((group, index) => (
          <div key={index} className="pallet-group-assignment">
            <h5>Group {group.palletGroup}</h5>
            <div className="pallet-quantity">
              <button onClick={() => handleQuantityChange(index, -1)} disabled={group.palletQuantity === 1}>
                <FaMinus />
              </button>
              <span>{group.palletQuantity} {group.palletQuantity === 1 ? 'Pallet' : 'Pallets'}</span>
              <button onClick={() => handleQuantityChange(index, 1)} disabled={index === palletGroups.length - 1 || palletGroups[index + 1].palletQuantity === 1}>
                <FaPlus />
              </button>
            </div>
            <button
              onClick={() => handleCharityAssignment(index)}
              className={`assign-button ${group.charityId ? 'assigned' : ''}`}
            >
              {group.charityId
                ? `Assigned to: ${group.charityName}`
                : 'Assign Charity'}
            </button>
            <div className="quote-upload">
              <label>
                <FaUpload className="icon" /> {group.quoteUrl ? 'Replace Quote' : 'Upload Quote'}
                <input
                  type="file"
                  onChange={(e) => handleQuoteUpload(index, e.target.files[0])}
                />
              </label>
              {(group.quoteFile || group.quoteUrl) && <span className="quote-uploaded">Quote {group.quoteFile ? 'selected' : 'uploaded'}</span>}
            </div>
            <div className="quote-price">
              <FaDollarSign className="icon" />
              <input
                type="text"
                value={focusedInputs[index] ? group.quotePrice : formatCurrency(group.quotePrice)}
                onChange={(e) => handleQuotePriceChange(index, e.target.value)}
                onFocus={() => handleQuotePriceFocus(index)}
                onBlur={() => handleQuotePriceBlur(index)}
                placeholder="Quote price"
              />
            </div>
            {index !== 0 && (
              <button onClick={() => handleRemoveGroup(index)} className="remove-group-btn">
                Remove Group
              </button>
            )}
          </div>
        ))}
        <button onClick={handleAddGroup} className="add-group-btn">
          Add Group
        </button>
      </div>
      <button className="save-button" onClick={handleSave} disabled={isUploading}>
        <FaSave className="icon" /> {isUploading ? 'Saving...' : 'Save Assignments'}
      </button>
      {showPopup && (
        <div className="popup">
          <p>Assignments saved successfully!</p>
        </div>
      )}
      {errorMessage && (
        <div className="error-message">
          <p>{errorMessage}</p>
        </div>
      )}
    </div>
  );
};

export default AssignDonation;