// src/App.js

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LandingPage from './components/LandingPage.js';
import Login from './components/Login.js';
import NewCompanySignUp from './components/NewCompanySignUp';
import MyTeam from './components/MyTeam';
import StartNewDonation from './components/StartNewDonation.js';
import ManageDonations from './components/ManageDonations.js';
import CreateNewCharity from './components/CreateNewCharity.js';
import Purchases from './components/Purchase.js';
import ManageLogistics from './components/ManageLogistics.js';
import ApprovalRequests from './components/ApprovalRequests.js';
import Reports from './components/Reports.js';
import { authService } from './services/authService';
import { userService } from './services/userService';
import OptionAnalytics from './components/OptionAnalytics.js';
import Help from './components/Help.js';

const PrivateRoute = ({ element, allowedRoles }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await authService.getCurrentUser();
      if (currentUser) {
        const role = await userService.getUserRole(currentUser.uid);
        setUser({ ...currentUser, role });
      }
      setLoading(false);
    };
    fetchUser();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (allowedRoles.includes(user.role)) {
    return element;
  } else {
    return <Navigate to="/" />;
  }
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<NewCompanySignUp />} />
        <Route 
          path="/my-team" 
          element={<PrivateRoute element={<MyTeam />} allowedRoles={['SystemAdmin', 'Admin', 'User']} />} 
        />
        <Route 
          path="/start-new-donation" 
          element={<PrivateRoute element={<StartNewDonation />} allowedRoles={['Admin', 'User']} />} 
        />
        <Route 
          path="/option-analytics" 
          element={<PrivateRoute element={<OptionAnalytics />} allowedRoles={['SystemAdmin', 'Admin', 'User']} />} 
        />
        <Route 
          path="/manage-donations" 
          element={<PrivateRoute element={<ManageDonations />} allowedRoles={['SystemAdmin']} />} 
        />
        <Route 
          path="/create-new-charity" 
          element={<PrivateRoute element={<CreateNewCharity />} allowedRoles={['SystemAdmin']} />} 
        />
        <Route 
          path="/purchase-orders" 
          element={<PrivateRoute element={<Purchases />} allowedRoles={['Admin', 'User']} />} 
        />
        <Route 
          path="/manage-logistics" 
          element={<PrivateRoute element={<ManageLogistics />} allowedRoles={['SystemAdmin']} />} 
        />
        <Route 
          path="/approval-requests" 
          element={<PrivateRoute element={<ApprovalRequests />} allowedRoles={['Admin', 'User']} />} 
        />
        <Route 
          path="/reports" 
          element={<PrivateRoute element={<Reports />} allowedRoles={['SystemAdmin', 'Admin', 'User']} />} 
        />
        <Route 
          path="/help" 
          element={<PrivateRoute element={<Help />} allowedRoles={['SystemAdmin', 'Admin', 'User']} />} 
        />
      </Routes>
    </Router>
  );
}

export default App;