// src/components/StartNewDonation.js

import React, { useState, useEffect, useCallback } from 'react';
import { FaUpload, FaFileAlt, FaTrash, FaSpinner, FaBuilding, FaClipboard, FaCalendarAlt, FaDollarSign, FaPallet, FaBalanceScale, FaClock, FaBoxOpen, FaFileInvoice, FaListAlt, FaPlus, FaMinus } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Papa from 'papaparse';
import { v4 as uuidv4 } from 'uuid';

import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Loader from '../components/Loader';
import { authService } from '../services/authService';
import { donationService } from '../services/donationService';
import { distributionCenterService } from '../services/distributionCenterService';
import { userService } from '../services/userService';

import '../styles/StartNewDonation.css';

function StartNewDonation() {
  const [user, setUser] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [notes, setNotes] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState('');
  const [pendingDonations, setPendingDonations] = useState([]);
  const [selectedDC, setSelectedDC] = useState('');
  const [distributionCenters, setDistributionCenters] = useState([]);
  const [donationMode, setDonationMode] = useState('automatic');

  const fetchPendingDonations = useCallback(async (distributionCenterId) => {
    try {
      const donations = await donationService.getByDistributionCenter(distributionCenterId);
      const pending = donations.filter(donation => 
        donation.status && donation.status.toLowerCase() === 'pending'
      );
      setPendingDonations(pending);
    } catch (error) {
      console.error('Error fetching pending donations:', error);
      toast.error('Failed to fetch pending donations. Please refresh the page.');
    }
  }, []);

  useEffect(() => {
    const fetchUserAndDonations = async () => {
      try {
        const currentUser = authService.getUserSession();
        if (currentUser) {
          const fullUserData = await userService.getById(currentUser.id);
          setUser(fullUserData);

          let userDCs = fullUserData.distributionCenters || [fullUserData.distributionCenter];

          if (userDCs.length > 0) {
            const dcs = await Promise.all(userDCs.map(dcId => 
              distributionCenterService.getById(dcId)
            ));
            setDistributionCenters(dcs);
            setSelectedDC(userDCs[0]);
            await fetchPendingDonations(userDCs[0]);
          } else {
            toast.error('No distribution centers assigned. Please contact support.');
          }
        } else {
          toast.error('Please log in to view this page.');
        }
      } catch (error) {
        console.error('Error in fetchUserAndDonations:', error);
        toast.error('An error occurred while fetching user data. Please try again.');
      }
    };
    fetchUserAndDonations();
  }, [fetchPendingDonations]);

  const handleDCChange = async (e) => {
    const newSelectedDC = e.target.value;
    setSelectedDC(newSelectedDC);
    await fetchPendingDonations(newSelectedDC);
  };

  const validateCsvData = (data) => {
    const requiredFields = ['sku', 'description', 'quantity', 'unitofmeasure', 'estimatedvalue', 'palletcount', 'expirationdate', 'costbasis'];
    const errors = [];

    data.forEach((row, index) => {
      requiredFields.forEach(field => {
        if (row[field] === undefined || row[field] === '') {
          errors.push(`Row ${index + 2}: Missing ${field}`);
        }
      });

      if (isNaN(parseFloat(row.quantity)) || parseFloat(row.quantity) <= 0) {
        errors.push(`Row ${index + 2}: Invalid quantity`);
      }
      if (isNaN(parseFloat(row.estimatedvalue)) || parseFloat(row.estimatedvalue) < 0) {
        errors.push(`Row ${index + 2}: Invalid estimated value`);
      }
      if (isNaN(parseInt(row.palletcount)) || parseInt(row.palletcount) < 0) {
        errors.push(`Row ${index + 2}: Invalid pallet count`);
      }
      if (isNaN(Date.parse(row.expirationdate))) {
        errors.push(`Row ${index + 2}: Invalid expiration date`);
      }
      if (isNaN(parseFloat(row.costbasis)) || parseFloat(row.costbasis) < 0) {
        errors.push(`Row ${index + 2}: Invalid cost basis`);
      }
    });

    return errors;
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setFileName(file.name);
    setCsvData([]);

    Papa.parse(file, {
      complete: (result) => {
        if (result.data && result.data.length > 1) {
          const headers = result.data[0].map(header => header.toLowerCase());
          const parsedData = result.data.slice(1)
            .filter(row => row.length === headers.length && row.some(cell => cell !== ''))
            .map(row => {
              const item = {};
              headers.forEach((header, index) => {
                item[header] = row[index];
              });
              return item;
            });

          const validationErrors = validateCsvData(parsedData);
          if (validationErrors.length > 0) {
            toast.error(validationErrors.join('\n'));
          } else {
            setCsvData(parsedData);
          }
        } else {
          toast.error('The CSV file is empty or invalid');
        }
      },
      header: false,
      error: (error) => {
        toast.error(`Error parsing CSV: ${error.message}`);
      }
    });
  };

  const handleSubmit = async (e, manualItems = null) => {
    e.preventDefault();
    if (donationMode === 'automatic' && csvData.length === 0) {
      toast.error('Please upload a valid CSV file');
      return;
    }

    if (!selectedDC) {
      toast.error('Please select a distribution center');
      return;
    }

    setIsLoading(true);

    try {
      const donationItems = donationMode === 'automatic' 
        ? csvData.map(item => ({
            itemID: uuidv4(),
            sku: item.sku,
            description: item.description,
            quantity: parseFloat(item.quantity),
            unitOfMeasure: item.unitofmeasure,
            estimatedValue: parseFloat(item.estimatedvalue),
            palletCount: parseInt(item.palletcount),
            expirationDate: new Date(item.expirationdate),
            costBasis: parseFloat(item.costbasis),
            status: 'Pending'
          }))
        : manualItems.map(item => ({
            itemID: uuidv4(),
            sku: item.sku,
            description: item.description,
            quantity: parseFloat(item.quantity),
            unitOfMeasure: item.unitOfMeasure,
            estimatedValue: parseFloat(item.estimatedValue),
            palletCount: parseInt(item.palletCount),
            expirationDate: new Date(item.expirationDate),
            costBasis: parseFloat(item.costBasis),
            status: 'Pending'
          }));

      const totalEstimatedValue = donationItems.reduce((sum, item) => sum + item.estimatedValue, 0);
      const totalPalletCount = donationItems.reduce((sum, item) => sum + item.palletCount, 0);
      const totalCostBasis = donationItems.reduce((sum, item) => sum + item.costBasis, 0);

      const donationData = {
        companyId: user.company,
        distributionCenterId: selectedDC,
        createdBy: user.id,
        status: 'Pending',
        items: donationItems,
        totalEstimatedValue,
        totalPalletCount,
        totalCostBasis,
        notes
      };

      const newDonationId = await donationService.create(donationData);
      console.log('New donation created with ID:', newDonationId);

      toast.success('Donation submitted successfully!');
      setCsvData([]);
      setFileName('');
      setNotes('');
      await fetchPendingDonations(selectedDC);
    } catch (error) {
      console.error('Error creating donation:', error);
      toast.error('Failed to create donation. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveFile = () => {
    setCsvData([]);
    setFileName('');
  };

  if (!user) return <Loader />;

  return (
    <div className="app-container">
      <Header user={user} />
      <div className="main-content">
        <Sidebar />
        <div className="start-new-donation-container">
          <h1><FaBoxOpen /> Start New Donation</h1>
          <DonationForm 
            handleSubmit={handleSubmit}
            handleFileUpload={handleFileUpload}
            fileName={fileName}
            handleRemoveFile={handleRemoveFile}
            csvData={csvData}
            notes={notes}
            setNotes={setNotes}
            isLoading={isLoading}
            distributionCenters={distributionCenters}
            selectedDC={selectedDC}
            handleDCChange={handleDCChange}
            donationMode={donationMode}
            setDonationMode={setDonationMode}
          />
          {csvData.length > 0 && donationMode === 'automatic' && <CsvPreview csvData={csvData} />}
          <PendingDonations pendingDonations={pendingDonations} />
        </div>
      </div>
      <ToastContainer position="bottom-right" autoClose={5000} />
    </div>
  );
}

function DonationForm({ handleSubmit, handleFileUpload, fileName, handleRemoveFile, csvData, notes, setNotes, isLoading, distributionCenters, selectedDC, handleDCChange, donationMode, setDonationMode }) {
  return (
    <div className="start-new-donation-form">
      <h2><FaFileInvoice /> New Donation Details</h2>
      <div className="form-group">
        <label htmlFor="donation-mode"><FaListAlt /> Donation Mode</label>
        <select
          id="donation-mode"
          value={donationMode}
          onChange={(e) => setDonationMode(e.target.value)}
        >
          <option value="automatic">Automatic (CSV Upload)</option>
          <option value="manual">Manual Entry</option>
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="distribution-center"><FaBuilding /> Distribution Center</label>
        <select
          id="distribution-center"
          value={selectedDC}
          onChange={handleDCChange}
          required
        >
          <option value="">Select a Distribution Center</option>
          {distributionCenters.map(dc => (
            <option key={dc.id} value={dc.id}>{dc.name}</option>
          ))}
        </select>
      </div>
      {donationMode === 'automatic' ? (
        <AutomaticDonationForm
          handleFileUpload={handleFileUpload}
          fileName={fileName}
          handleRemoveFile={handleRemoveFile}
        />
      ) : (
        <ManualDonationForm handleSubmit={handleSubmit} />
      )}
      <div className="form-group">
        <label htmlFor="notes"><FaClipboard /> Additional Notes</label>
        <textarea
          id="notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          rows="4"
          placeholder="Enter any additional notes here..."
        />
      </div>
      <button 
        type="submit" 
        onClick={(e) => handleSubmit(e, donationMode === 'manual' ? ManualDonationForm.getItems() : null)}
        disabled={isLoading || (donationMode === 'automatic' && csvData.length === 0) || !selectedDC}
        className="submit-button"
      >
        {isLoading ? (
          <>
            <FaSpinner className="spinner" /> Submitting...
          </>
        ) : (
          <>
            <FaBoxOpen /> Submit Donation
          </>
        )}
      </button>
    </div>
  );
}

function AutomaticDonationForm({ handleFileUpload, fileName, handleRemoveFile }) {
  return (
    <div className="form-group file-upload">
      <label htmlFor="csv-upload">
        <FaUpload /> Upload Inventory CSV
      </label>
      <input
        type="file"
        id="csv-upload"
        accept=".csv"
        onChange={handleFileUpload}
        required
      />
      {fileName && (
        <div className="file-info">
          <FaFileAlt /> {fileName}
          <button type="button" onClick={handleRemoveFile} className="remove-file" aria-label="Remove file">
            <FaTrash />
          </button>
        </div>
      )}
    </div>
  );
}

function ManualDonationForm({ handleSubmit }) {
  const [items, setItems] = useState([{ 
    sku: '', description: '', quantity: '', unitOfMeasure: '', 
    estimatedValue: '', palletCount: '', expirationDate: '', costBasis: '' 
  }]);

  const handleItemChange = (index, field, value) => {
    const newItems = [...items];
    newItems[index][field] = value;
    setItems(newItems);
  };

  const addItem = () => {
    setItems([...items, { 
      sku: '', description: '', quantity: '', unitOfMeasure: '', 
      estimatedValue: '', palletCount: '', expirationDate: '', costBasis: '' 
    }]);
  };

  const removeItem = (index) => {
    if (items.length > 1) {
      const newItems = items.filter((_, i) => i !== index);
      setItems(newItems);
    }
  };

  ManualDonationForm.getItems = () => items;

  return (
    <form onSubmit={handleSubmit}>
      {items.map((item, index) => (
        <div key={index} className="item-form">
          <h3>Item {index + 1}</h3>
          <div className="form-group">
            <label htmlFor={`sku-${index}`}>SKU</label>
            <input
              id={`sku-${index}`}
              type="text"
              value={item.sku}
              onChange={(e) => handleItemChange(index, 'sku', e.target.value)}
              required
            />
          </div>
          <div className="form-group">
          <label htmlFor={`description-${index}`}>Description</label>
            <input
              id={`description-${index}`}
              type="text"
              value={item.description}
              onChange={(e) => handleItemChange(index, 'description', e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor={`quantity-${index}`}>Quantity</label>
            <input
              id={`quantity-${index}`}
              type="number"
              value={item.quantity}
              onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
              required
              min="0"
            />
          </div>
          <div className="form-group">
            <label htmlFor={`unitOfMeasure-${index}`}>Unit of Measure</label>
            <input
              id={`unitOfMeasure-${index}`}
              type="text"
              value={item.unitOfMeasure}
              onChange={(e) => handleItemChange(index, 'unitOfMeasure', e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor={`estimatedValue-${index}`}>Estimated Value</label>
            <input
              id={`estimatedValue-${index}`}
              type="number"
              value={item.estimatedValue}
              onChange={(e) => handleItemChange(index, 'estimatedValue', e.target.value)}
              required
              min="0"
              step="0.01"
            />
          </div>
          <div className="form-group">
            <label htmlFor={`palletCount-${index}`}>Pallet Count</label>
            <input
              id={`palletCount-${index}`}
              type="number"
              value={item.palletCount}
              onChange={(e) => handleItemChange(index, 'palletCount', e.target.value)}
              required
              min="0"
            />
          </div>
          <div className="form-group">
            <label htmlFor={`expirationDate-${index}`}>Expiration Date</label>
            <input
              id={`expirationDate-${index}`}
              type="date"
              value={item.expirationDate}
              onChange={(e) => handleItemChange(index, 'expirationDate', e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor={`costBasis-${index}`}>Cost Basis</label>
            <input
              id={`costBasis-${index}`}
              type="number"
              value={item.costBasis}
              onChange={(e) => handleItemChange(index, 'costBasis', e.target.value)}
              required
              min="0"
              step="0.01"
            />
          </div>
          {items.length > 1 && (
            <button type="button" onClick={() => removeItem(index)} className="remove-item">
              <FaMinus /> Remove Item
            </button>
          )}
        </div>
      ))}
      <button type="button" onClick={addItem} className="add-item">
        <FaPlus /> Add Item
      </button>
    </form>
  );
}

function CsvPreview({ csvData }) {
  if (csvData.length === 0) return null;

  return (
    <div className="csv-preview">
      <h3><FaListAlt /> CSV Preview</h3>
      <div className="csv-table-container">
        <table>
          <thead>
            <tr>
              <th>SKU</th>
              <th>Description</th>
              <th>Quantity</th>
              <th>Unit</th>
              <th>Value</th>
              <th>Pallets</th>
              <th>Expiration Date</th>
              <th>Cost Basis</th>
            </tr>
          </thead>
          <tbody>
            {csvData.map((row, index) => (
              <tr key={index}>
                <td>{row.sku}</td>
                <td>{row.description}</td>
                <td>{row.quantity}</td>
                <td>{row.unitofmeasure}</td>
                <td>${parseFloat(row.estimatedvalue).toFixed(2)}</td>
                <td>{row.palletcount}</td>
                <td>{new Date(row.expirationdate).toLocaleDateString()}</td>
                <td>${parseFloat(row.costbasis).toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <p className="preview-note">Showing all {csvData.length} items</p>
    </div>
  );
}

function PendingDonations({ pendingDonations }) {
  return (
    <div className="pending-donations">
      <h3><FaClock /> Pending Donations</h3>
      {pendingDonations.length > 0 ? (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th><FaCalendarAlt /> Date</th>
                <th><FaDollarSign /> Total Value</th>
                <th><FaPallet /> Total Pallets</th>
                <th><FaBalanceScale /> Total Cost Basis</th>
                <th><FaClock /> Status</th>
              </tr>
            </thead>
            <tbody>
              {pendingDonations.map((donation) => (
                <tr key={donation.id}>
                  <td>{donation.createdAt ? new Date(donation.createdAt.seconds * 1000).toLocaleDateString() : 'N/A'}</td>
                  <td>${donation.totalEstimatedValue ? donation.totalEstimatedValue.toFixed(2) : 'N/A'}</td>
                  <td>{donation.totalPalletCount || 'N/A'}</td>
                  <td>${donation.totalCostBasis ? donation.totalCostBasis.toFixed(2) : 'N/A'}</td>
                  <td>{donation.status || 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="no-donations">No pending donations</p>
      )}
    </div>
  );
}

export default StartNewDonation;