// src/components/Purchase.js

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { FaFileInvoiceDollar, FaEye, FaSignature, FaCheck, FaTimes, FaCalendarAlt, FaBuilding, FaDollarSign, FaUser, FaFilter, FaSort, FaFlag, FaClock, FaCheckCircle } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Loader from '../components/Loader';
import ErrorMessage from '../components/ErrorMessage';
import { purchaseService } from '../services/purchaseService';
import { authService } from '../services/authService';
import { userService } from '../services/userService';
import { distributionCenterService } from '../services/distributionCenterService';
import '../styles/Purchase.css';

const statusOptions = [
  { value: 'pending', icon: FaClock, color: '#FFA500', label: 'Pending' },
  { value: 'approved', icon: FaCheckCircle, color: '#28A745', label: 'Approved' },
  { value: 'completed', icon: FaFlag, color: '#17a2b8', label: 'Completed' },
];

const StatusIndicator = ({ status }) => {
  const statusOption = statusOptions.find(option => option.value === status) || statusOptions[0];
  const Icon = statusOption.icon;
  
  return (
    <span 
      className="status-indicator" 
      style={{ backgroundColor: statusOption.color }}
      data-tooltip-id="tooltip" 
      data-tooltip-content={statusOption.label}
    >
      <Icon /> {statusOption.label}
    </span>
  );
};

const Purchases = () => {
  const [state, setState] = useState({
    purchaseOrders: [],
    user: null,
    loading: true,
    error: null,
    filters: {
      status: 'all',
      dateRange: { start: null, end: null },
      searchTerm: '',
    },
    sort: {
      field: 'date',
      direction: 'desc',
    },
    pagination: {
      currentPage: 1,
      itemsPerPage: 10,
    },
    distributionCenters: [],
    selectedDC: 'all',
  });

  const [selectedPO, setSelectedPO] = useState(null);
  const [signature, setSignature] = useState(null);
  const [approverName, setApproverName] = useState('');
  const [pdfUrl, setPdfUrl] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      setState(prev => ({ ...prev, loading: true, error: null }));

      const currentUser = authService.getUserSession();
      if (!currentUser) throw new Error('No authenticated user found');

      const userData = await userService.getById(currentUser.id);
      if (!userData) throw new Error('User data not found');

      const userDCs = userData.distributionCenters || [userData.distributionCenter];
      const dcs = await Promise.all(userDCs.map(dcId => 
        distributionCenterService.getById(dcId)
      ));

      const pos = await Promise.all(dcs.map(dc => 
        purchaseService.fetchAllPurchaseOrders(dc.id)
      ));

      setState(prev => ({ 
        ...prev, 
        user: userData, 
        purchaseOrders: pos.flat(),
        distributionCenters: dcs,
        loading: false,
      }));
    } catch (error) {
      console.error('Error fetching data:', error);
      setState(prev => ({ ...prev, error: error.message, loading: false }));
      toast.error('Failed to load purchase orders. Please refresh the page.');
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleViewPO = useCallback(async (po) => {
    setSelectedPO(po);
    setSignature(null);
    if (po.approverId) {
      const name = await userService.getUserNameById(po.approverId);
      setApproverName(name);
    } else {
      setApproverName('');
    }
    
    try {
      if (po.status === 'pending') {
        const pdfBlob = await purchaseService.generatePDF(po);
        const url = URL.createObjectURL(pdfBlob);
        setPdfUrl(url);
      } else {
        setPdfUrl(po.pdfUrl);
      }
    } catch (error) {
      console.error('Error generating or fetching PDF:', error);
      setPdfUrl(null);
      toast.error('Failed to load PDF. Please try again.');
    }
  }, []);

  const handleClosePODetails = useCallback(() => {
    setSelectedPO(null);
    setPdfUrl(null);
  }, []);

  const handleSign = useCallback(() => {
    if (signature) {
      const signatureImage = signature.toDataURL();
      setSelectedPO((prevPO) => ({ ...prevPO, signature: signatureImage }));
    }
  }, [signature]);

  const handleApprovePO = useCallback(async () => {
    if (!selectedPO || !selectedPO.signature) return;

    try {
      setState(prev => ({ ...prev, loading: true }));
      const currentUser = authService.getUserSession();
      if (!currentUser) throw new Error('No authenticated user found');

      const approvalData = {
        signature: selectedPO.signature,
        approverId: currentUser.id,
        approvalTimestamp: new Date().toISOString()
      };

      await purchaseService.approvePurchaseOrder(selectedPO.id, approvalData);
      await fetchData();
      setSelectedPO(null);
      setPdfUrl(null);
      toast.success('Purchase order approved successfully');
    } catch (error) {
      console.error('Error approving purchase order:', error);
      toast.error('Failed to approve purchase order. Please try again.');
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  }, [selectedPO, fetchData]);

  const handleClearSignature = useCallback(() => {
    if (signature) {
      signature.clear();
      setSelectedPO((prevPO) => ({ ...prevPO, signature: null }));
    }
  }, [signature]);

  const handleGenerateSignature = useCallback(() => {
    if (!state.user || !state.user.name) return;

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = 300;
    canvas.height = 100;

    context.font = '40px "Brush Script MT", "Brush Script Std", "Lucida Calligraphy", "Lucida Handwriting", "Apple Chancery", cursive';
    context.fillStyle = 'black';
    
    const textWidth = context.measureText(state.user.name).width;
    const xPosition = (canvas.width - textWidth) / 2;
    
    context.fillText(state.user.name, xPosition, 60);

    const signatureImage = canvas.toDataURL();
    setSelectedPO((prevPO) => ({ ...prevPO, signature: signatureImage }));
    if (signature && signature.fromDataURL) {
      signature.fromDataURL(signatureImage);
    }
  }, [state.user, signature]);

  const handleSort = useCallback((field) => {
    setState(prev => ({
      ...prev,
      sort: {
        field,
        direction: prev.sort.field === field && prev.sort.direction === 'asc' ? 'desc' : 'asc'
      }
    }));
  }, []);

  const handleFilter = useCallback((name, value) => {
    setState(prev => ({
      ...prev,
      filters: { ...prev.filters, [name]: value }
    }));
  }, []);

  const filteredAndSortedPOs = useMemo(() => {
    return state.purchaseOrders
      .filter(po => {
        const searchLower = state.filters.searchTerm.toLowerCase();
        const matchesSearch = 
          po.id.toLowerCase().includes(searchLower);
        
        const matchesStatus = state.filters.status === 'all' || po.status === state.filters.status;
    
        const matchesDateRange = 
          (!state.filters.dateRange.start || !state.filters.dateRange.end) ||
          (new Date(po.date) >= new Date(state.filters.dateRange.start) &&
           new Date(po.date) <= new Date(state.filters.dateRange.end));
    
        const matchesDC = state.selectedDC === 'all' || po.distributionCenterId === state.selectedDC;

        return matchesSearch && matchesStatus && matchesDateRange && matchesDC;
      })
      .sort((a, b) => {
        if (a[state.sort.field] < b[state.sort.field]) return state.sort.direction === 'asc' ? -1 : 1;
        if (a[state.sort.field] > b[state.sort.field]) return state.sort.direction === 'asc' ? 1 : -1;
        return 0;
      });
  }, [state.purchaseOrders, state.filters, state.sort, state.selectedDC]);

  const paginatedPOs = useMemo(() => {
    const startIndex = (state.pagination.currentPage - 1) * state.pagination.itemsPerPage;
    return filteredAndSortedPOs.slice(startIndex, startIndex + state.pagination.itemsPerPage);
  }, [filteredAndSortedPOs, state.pagination]);

  const totalPages = Math.ceil(filteredAndSortedPOs.length / state.pagination.itemsPerPage);

  if (state.loading) return <Loader />;
  if (state.error) return <ErrorMessage message={state.error} />;

  return (
    <div className="app-container">
      <Header user={state.user} />
      <div className="main-content">
        <Sidebar />
        <div className="purchases-container">
          <h1><FaFileInvoiceDollar /> Purchase Orders</h1>
          
          <FilterBar 
            filters={state.filters}
            onFilterChange={handleFilter}
            statusOptions={statusOptions}
            distributionCenters={state.distributionCenters}
            selectedDC={state.selectedDC}
            onDCChange={(value) => setState(prev => ({ ...prev, selectedDC: value }))}
          />

          <PurchaseOrderTable 
            purchaseOrders={paginatedPOs}
            onViewPO={handleViewPO}
            sort={state.sort}
            onSort={handleSort}
          />

          <Pagination
            currentPage={state.pagination.currentPage}
            totalPages={totalPages}
            onPageChange={(page) => setState(prev => ({ ...prev, pagination: { ...prev.pagination, currentPage: page } }))}
          />

          {selectedPO && (
            <div className="po-details-container">
              <PurchaseOrderDetails 
                po={selectedPO}
                signature={signature}
                setSignature={setSignature}
                onSign={handleSign}
                onClearSignature={handleClearSignature}
                onApprove={handleApprovePO}
                onClose={handleClosePODetails}
                approverName={approverName}
                onGenerateSignature={handleGenerateSignature}
                userName={state.user ? state.user.name : ''}
              />
              <PurchaseOrderPDF pdfUrl={pdfUrl} />
            </div>
          )}
        </div>
      </div>
      <ToastContainer position="bottom-right" autoClose={5000} />
      <Tooltip id="tooltip" />
    </div>
  );
};

const FilterBar = React.memo(({ filters, onFilterChange, statusOptions, distributionCenters, selectedDC, onDCChange }) => (
  <div className="filters">
    <div className="filter-group">
      <FaFilter className="icon" />
      <input 
        type="text" 
        placeholder="Search by PO ID" 
        value={filters.searchTerm}
        onChange={(e) => onFilterChange('searchTerm', e.target.value)}
      />
    </div>
    <div className="filter-group">
      <FaCalendarAlt className="icon" />
      <select 
        value={filters.status} 
        onChange={(e) => onFilterChange('status', e.target.value)}
      >
        <option value="all">All Statuses</option>
        {statusOptions.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
    <div className="filter-group">
      <FaCalendarAlt className="icon" />
      <input 
        type="date" 
        value={filters.dateRange.start || ''} 
        onChange={(e) => onFilterChange('dateRange', { ...filters.dateRange, start: e.target.value })}
      />
      <span>to</span>
      <input 
        type="date" 
        value={filters.dateRange.end || ''} 
        onChange={(e) => onFilterChange('dateRange', { ...filters.dateRange, end: e.target.value })}
      />
    </div>
    <div className="filter-group">
      <FaBuilding className="icon" />
      <select 
        value={selectedDC} 
        onChange={(e) => onDCChange(e.target.value)}
      >
        <option value="all">All Distribution Centers</option>
        {distributionCenters.map(dc => (
          <option key={dc.id} value={dc.id}>{dc.name}</option>
        ))}
      </select>
    </div>
    <button className="btn btn-reset" onClick={() => {
      onFilterChange('status', 'all');
      onFilterChange('dateRange', { start: null, end: null });
      onFilterChange('searchTerm', '');
      onDCChange('all');
    }}>Reset Filters</button>
  </div>
));

const PurchaseOrderTable = React.memo(({ purchaseOrders, onViewPO, sort, onSort }) => {
  const renderSortIcon = (field) => {
    if (sort.field !== field) return <FaSort />;
    return sort.direction === 'asc' ? <FaSort className="asc" /> : <FaSort className="desc" />;
  };

  return (
    <div className="purchase-orders-table-container">
      <table className="purchase-orders-table">
        <thead>
          <tr>
            <th onClick={() => onSort('id')}>PO ID {renderSortIcon('id')}</th>
            <th onClick={() => onSort('date')}>Date {renderSortIcon('date')}</th>
            <th onClick={() => onSort('amount')}>Amount {renderSortIcon('amount')}</th>
            <th onClick={() => onSort('status')}>Status {renderSortIcon('status')}</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {purchaseOrders.map(po => (
            <tr key={po.id}>
              <td data-label="PO ID">{po.id}</td>
              <td data-label="Date">{new Date(po.date).toLocaleDateString()}</td>
              <td data-label="Amount">${po.amount.toFixed(2)}</td>
              <td data-label="Status">
                <StatusIndicator status={po.status} />
              </td>
              <td data-label="Actions">
                <button className="btn btn-view" onClick={() => onViewPO(po)}>
                  <FaEye /> View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});

const PurchaseOrderDetails = React.memo(({ 
  po, 
  signature, 
  setSignature, 
  onSign, 
  onClearSignature, 
  onApprove, 
  onClose,
  approverName,
  onGenerateSignature,
  userName
}) => (
  <div className="po-details">
    <div className="po-details-header">
      <h2>Purchase Order Details</h2>
      <button className="btn-close" onClick={onClose}>
        <FaTimes />
      </button>
    </div>
    <div className="po-information">
      <h3>PO Information</h3>
      <div className="info-group">
        <strong><FaFileInvoiceDollar /> PO ID:</strong>
        <span>{po.id}</span>
      </div>
      <div className="info-group">
        <strong><FaCalendarAlt /> Date:</strong>
        <span>{new Date(po.date).toLocaleDateString()}</span>
      </div>
      <div className="info-group">
        <strong><FaDollarSign /> Amount:</strong>
        <span>${po.amount.toFixed(2)}</span>
      </div>
      <div className="info-group">
        <strong><FaBuilding /> Distribution Center:</strong>
        <span>{po.distributionCenterName}</span>
      </div>
      <div className="info-group">
        <strong><FaUser /> Requester:</strong>
        <span>{po.originalDonationCreatorName}</span>
      </div>
    </div>
    
    {po.status === 'approved' && (
      <div className="approval-information">
        <h3>Approval Information</h3>
        <div className="info-group">
          <strong><FaUser /> Approved By:</strong>
          <span>{approverName}</span>
        </div>
        <div className="info-group">
          <strong><FaCalendarAlt /> Approval Date:</strong>
          <span>{new Date(po.approvalTimestamp).toLocaleString()}</span>
        </div>
      </div>
    )}

    {po.status === 'pending' && (
      <div className="signature-container">
        <h3>Signature</h3>
        <SignatureCanvas
          ref={(ref) => setSignature(ref)}
          canvasProps={{ className: 'signature-canvas' }}
        />
        <div className="signature-actions">
          <button className="btn btn-sign" onClick={onSign} disabled={!signature}>
            <FaSignature /> Sign
          </button>
          <button className="btn btn-generate-signature" onClick={onGenerateSignature}>
            <FaUser /> Generate Signature
          </button>
          <button className="btn btn-clear" onClick={onClearSignature}>
            Clear Signature
          </button>
        </div>
        <button 
          className="btn btn-approve" 
          onClick={onApprove} 
          disabled={!po.signature}
        >
          <FaCheck /> Approve Purchase Order
        </button>
      </div>
    )}
  </div>
));

const PurchaseOrderPDF = React.memo(({ pdfUrl }) => (
  <div className="po-pdf-container">
    <h3>Purchase Order PDF</h3>
    {pdfUrl ? (
      <iframe
        src={pdfUrl}
        title="Purchase Order PDF"
        width="100%"
        height="600px"
      />
    ) : (
      <p>Loading PDF...</p>
    )}
  </div>
));

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <div className="pagination">
      <button 
        onClick={() => onPageChange(currentPage - 1)} 
        disabled={currentPage === 1}
      >
        Previous
      </button>
      <span>{currentPage} of {totalPages}</span>
      <button 
        onClick={() => onPageChange(currentPage + 1)} 
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};

export default Purchases;